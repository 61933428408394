import React from 'react';
import 'normalize.css';
import GlobalStyles from '../styles/GlobalStyles';
import Typography from '../styles/Typography';

export default function HomePage() {
  return (
    <div>
      <GlobalStyles />
      <Typography />
      <div className="header">
        <h3 className="title">Reconnect Earth</h3>
      </div>
      <div className="panel2">
        <h1>TRIVIA NIGHT</h1>
        <h2>THURSDAY January 28th</h2>
        <h2>6-8PM PST</h2>
      </div>
      <div className="panel3">
        <p>A Fundraising Event Supporting Program Scholarships</p>
      </div>
      <div className="panel4">
        <h4>About The Event</h4>

        <p className="final">FIRST PLACE: $50 REI E-GIFT CARD</p>
        <p className="final">SECOND PLACE: $25 REI E-GIFT CARD</p>
        <br />
        <p className="list">TEAM SIZE: UP TO 8</p>
        <p className="list">COST: $5 PER PERSON</p>
        <p className="list">DURATION: 2 HOURS</p>
        <p className="final">CATEGORIES: 5</p>
      </div>
      <div className="panel5">
        <h4>How It Works</h4>
        <p>
          This trivia night will be played via Zoom, you’re in for a night of
          fun, learning, and most important, trivia bragging rights!
        </p>
      </div>
      <div className="panel6">
        <button type="button">
          <a href="https://fs3.formsite.com/t1Jnpr/cijyg14xcu/index.html">
            REGISTER
          </a>
        </button>
      </div>
    </div>
  );
}
