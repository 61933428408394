import { createGlobalStyle } from 'styled-components';
import mountain from '../assets/images/mountain.jpg';
import students from '../assets/images/students.jpg';

const GlobalStyles = createGlobalStyle`
  :root {
    --primary: #F0EAD6;
    --accent1:#FFD53F;
    --accent2:#5B2510;
    --background: #244747;
  }
  html {
    font-size: 16px;
    color: var(--primary);
    height: 100%;
  }

  body {
    font-size: 2rem;
    height: 100%;
  }

  fieldset {
    border-color: rgba(0,0,0,0.1);
    border-width: 1px;
  }

  button {
    background: var(--accent1);
    color: var(--background);
    border: 0;
    padding: 0.6rem 1rem;
    border-radius: 4px;
    cursor: pointer;
    box-shadow: 1px 1px 3px 1px var(--background);
    text-shadow: 0.5px 0.5px 0 rgba(0,0,0,0.2);
    transition: all 0.2s;
    &:hover {
      --cast: 4px;
    }
    margin-top: 60px;
    margin-bottom: 60px;
  }

  button:hover {
    background: var(--background);
    color: var(--accent1);
    box-shadow: 1px 1px 3px 1px var(--accent1);
  }

  .gatsby-image-wrapper img[src*=base64\\,] {
    image-rendering: -moz-crisp-edges;
    image-rendering: pixelated;
  }

  /* Scrollbar Styles */
  body::-webkit-scrollbar {
    width: 12px;
  }
  html {
    scrollbar-width: thin;
    scrollbar-color: var(--red) var(--white);
  }
  body::-webkit-scrollbar-track {
    background: var(--white);
  }
  body::-webkit-scrollbar-thumb {
    background-color: var(--red) ;
    border-radius: 6px;
    border: 3px solid var(--white);
  }

  hr {
    /* border: 0; */
    /* height: 8px; */

    /* background-size: 1500px; */
  }

  img {
    max-width: 100%;
  }

  .tilt {
    transform: rotate(-2deg);
    position: relative;
    display: inline-block;
  }
  .title {
    text-align: center;
  }
  .header {
    background: var(--background);
    text-align: center;
  }
  .panel2{
    text-align: center;
    background-image: url(${mountain});
    background-repeat: no-repeat;
    background-size: cover;
    /* background-size: 450px; */
    /* background-attachment: fixed; */
  }
  .panel3 {
    background: var(--accent2);
    text-align: center;
  }
  .panel4 {
    background: var(--background);
    text-align: center;
  }
  .panel5 {
    text-align: center;
    background-image: url(${students});
    background-size: cover;
    background-repeat: no-repeat;
  }
  .panel6 {
    background: var(--accent2);
    display: flex;
    justify-content: center;
  }
 p {
   margin-top: 0;
   margin-bottom: 0;
   padding-top: 40px;
   padding-bottom: 40px;
 }

 p.list {
   padding-bottom: 15px;
   padding-top: 15px;
 }

 p.final {
   padding-top: 15px;
   padding-bottom: 40px;
 }

 h1, h2, h3, h4, h5, h6 {
   padding-top: 1rem;
   padding-bottom: 1rem;
 }

 h1, h2, h4, h5, h6 {
  padding-top: 30px;
 }


`;

export default GlobalStyles;
